import SplitSectionInternal from "./split-section-internal";
import { SplitSectionProps } from "./utils";

/**
 * Component for "SplitSection" Slices.
 */
const SplitSection = ({ slice }: SplitSectionProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      {/* Actual code */}
      <SplitSectionInternal slice={slice} />
    </section>
  );
};

export default SplitSection;
